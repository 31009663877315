<template>
  <div class="data-verify">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="data-verify-main">
      <div class="left">
        <Spin fix v-if="loading"></Spin>
        <div class="left-top" v-else>
          <div class="tit">
            <span>{{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{$t('trainingcamp_train_topic')}}(id:{{dataList.length && dataList[curIndex].id}})</span>
            <span>{{ dataList.length  ?  typesObj[dataList[curIndex].type] : ''}}{{$t('trainingcamp_train_topic')}}</span>
          </div>
          <div class="name"  style="display: inline-block;">
            <Richtxt :dataObj="{val:dataList.length ? dataList[curIndex].name : ''}" :isInline="true" :isDisabled="true"></Richtxt>
            <!--          {{ dataInfo.datasetData.name }}-->
          </div>
          <div class="cont">
            <!--        单选-->
            <RadioGroup v-model="radio" vertical v-if="dataList.length && (dataList[curIndex].type == 1 || dataList[curIndex].type == 3)">
              <Radio disabled :label="optionList[index] + ': ' + item.name" class="option"  v-for="(item,index) in dataList[curIndex].options" :key="item.id">
                <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{optionList[index]}}:</span>
                  <span style="display: inline-block;">
                    <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                  </span>
                </p></div>
              </Radio>
            </RadioGroup>
            <!--          多选-->
            <CheckboxGroup v-model="checkbox" v-if="dataList.length && dataList[curIndex].type == 2">
              <Checkbox disabled :label="optionList[index] + ': ' + item.name" class="checkoutOption"  v-for="(item,index) in dataList[curIndex].options" :key="item.id">
                <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{optionList[index]}}:</span>
                  <span style="display: inline-block;">
                    <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                  </span>
                </p></div>
              </Checkbox>
            </CheckboxGroup>
          </div>

          <div class="answer" v-if="correctAnswer">
            {{ $t('trainingcamp_train_correct_answer') }}：
            <span class="blue">
              {{correctAnswer}}
            </span>
          </div>
          <div class="answer-cont" v-if="dataList.length">
            <span>{{ $t('trainingcamp_train_analysis') }}：</span>
            <span style="display: inline-block;">
              <Richtxt :dataObj="{val:dataList[curIndex].explain}" :isInline="true" :isDisabled="true"></Richtxt>
            </span>
          </div>
          <div class="answer-cont" v-else>
            <span>{{ $t('trainingcamp_train_analysis') }}：</span><span style="display: inline-block;"></span>
          </div>
          <div class="bot">
            <div class="btn">
              <!-- //0待复核1通过2未通过的9已删除的 -->
<!--              <Button type="error" @click="verifyNotAllow" v-if="tabid in [0, 1, 2]">{{ $t('trainingcamp_train_btn_no_pass') }}</Button>-->
<!--              <Button type="primary" @click="verifyAllow" v-if="tabid in [0, 2]">{{ $t('trainingcamp_train_btn_pass') }}</Button>-->
<!--              <Button type="primary" @click="unVerify" v-if="tabid in [1, 2]">未审核</Button>-->
              <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
              <!--<Button type="primary" @click="commitAnswer" :disabled="!modifiable">{{$t('trainingcamp_train_confirm')}}</Button>-->
              <Button @click="next">{{$t('trainingcamp_train_next')}}</Button>
            </div>

          </div>

        </div>
      </div>
      <div class="right">
<!--        <div class="tab">-->
<!--          <div class="tab-list">-->
<!--            <div :class="tabid == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">-->
<!--              {{ item.name }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="count">-->
<!--            &lt;!&ndash; {{ $t('trainingcamp_train_correction_surplus') }}<span>{{ remainingTotal }}</span>{{$t('trainingcamp_train_correction_topic')}} &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <div class="search ">
          <div class="amount">
            {{ $t('trainingcamp_train_correction_surplus') }}<span>{{ remainingTotal }}</span>{{$t('trainingcamp_train_correction_topic')}}
          </div>
          <div class="search_input ">
            <Input v-model="keyword"
                   :placeholder="$t('trainingcamp_train_correction_key')"
                   clearable
                   search
                   :enter-button="true"
                   @on-enter="getList"
                   @on-search="getList"
            />
          </div>
        </div>
<!--        <div class="catrgory-search">-->
<!--          <Select v-model="categoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'categoryId')}">-->
<!--            <Option v-for="item in categoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--          </Select>-->
<!--          <Select v-model="secondCategoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'secondCategoryId')}">-->
<!--            <Option v-for="item in secondCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--          </Select>-->
<!--          <Select v-model="threeCategoryId" clearable @on-change="(data)=>{changeCategory(data,'threeCategoryId')}">-->
<!--            <Option v-for="item in threeCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--          </Select>-->
<!--        </div>-->
        <div class="right-top">
          <div class="tit">
            <div>
              {{ $t('trainingcamp_train_answer_sheet') }}
              <Tooltip>
                <Icon type="ios-help-circle-outline" />
                <div slot="content">
                  <p>{{ $t('trainingcamp_train_white') }}</p>
                  <p>{{ $t('trainingcamp_train_blue') }}</p>
                  <p>{{ $t('trainingcamp_train_green') }}</p>
                  <p>{{ $t('trainingcamp_train_red') }}</p>
                </div>
              </Tooltip>
            </div>
            <Button type="text" @click="refresh">{{ $t('trainingcamp_train_correction_refresh') }}</Button>
          </div>

          <div class="answer-card">
            <Spin fix v-if="loading"></Spin>
            <div class="list" v-else>
              <div :class="{active:dataList[curIndex].id == item.id,error:item.dataToSite && item.dataToSite.verify_status == '2',corret:item.dataToSite && item.dataToSite.verify_status == '1'} " v-for="(item,index) in dataList" :key="item.id" @click="changeAnswerCard(index)">{{index + 1}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Richtxt from '../../components/production/richtxt.vue';
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:50,
      tabid:0,  //0待复核1通过2未通过的9已删除的
      dataList:[],
      loading:true,
      allowLoading:false,
      notAllowLoading:false,
      unVerifyLoading:false,
      typesObj:{},
      curIndex:0,
      optionList:['A','B','C','D','E','F'],
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      radio:'',
      checkbox:[],
      correctIndex:'',
      correctAnswer:'',
      keyword:'',
      tabList:[
        {
          name:this.$t('trainingcamp_train_correction_verify'),
          id:0
        },
        {
          name:this.$t('trainingcamp_train_correction_pass'),
          id:1
        },
        {
          name:this.$t('trainingcamp_train_correction_fail'),
          id:2
        },
        {
          name:this.$t('trainingcamp_train_correction_del'),
          id:9
        }
      ],
      remainingTotal:0,
      categoryId:'',
      categoryList:[],  //一级分类
      secondCategoryId:'',
      secondCategoryList:[],
      threeCategoryId:'',
      threeCategoryList:[],
      delDataListLoading:false,
    }
  },
  components:{
    Richtxt
  },
  mounted(){
    // this.getCategoryList();
    this.categoryId = this.$route.query.categoryId || '';
    this.getList();
  },
  methods:{
    refresh(){
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        category_id:this.categoryId,
        keyword:this.keyword
      };

      this.api.dataset.datasetCategoryVerifyDataList(params).then((res)=>{
        this.dataList = res.data_list;
        this.curIndex = 0;
        this.correctAnswer = '';
        if(this.dataList.length){
          this.formateInfo();
        }
        this.typesObj = res.data_types;
        this.loading = false;
        this.remainingTotal = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeAnswerCard(index){
      this.curIndex = index;
      this.formateInfo();
    },
    next(){ //下一题
      if (this.dataList.length <= 0){
        return;
      }
      if(this.curIndex == this.dataList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      this.curIndex = this.curIndex + 1;
      this.formateInfo();
    },
    pre(){  //上一题
      if (this.dataList.length <= 0){
        return;
      }
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      this.curIndex = this.curIndex - 1;
      this.formateInfo();
    },
    formateInfo(){  //处理数据详情
      //console.log('formateInfo start')
      //console.log(this.dataList.length)
      //console.log('formateInfo t1')
      if (this.dataList.length <= 0){
        return;
      }
      if (!this.dataList[this.curIndex]){
        return ;
      }

      if(this.dataList[this.curIndex].type == '1' || this.dataList[this.curIndex].type == '3') {  //1单选 3判断

        this.correctIndex = this.dataList[this.curIndex].options.findIndex((item) => {
          return item.is_correct == 1;
        });

        this.correctAnswer = this.optionList[this.correctIndex];
        this.radio = this.optionList[this.correctIndex] + ': ' + this.dataList[this.curIndex].options[this.correctIndex].name;
      }


      if(this.dataList[this.curIndex].type == '2') {  //2多选

        let correctAnswerIndex = [];

        this.dataList[this.curIndex].options.forEach((item,index)=>{
          if(item.is_correct == '1'){
            correctAnswerIndex.push(index);
          }
        })

        let correctAnswer = [];
        this.checkbox = [];
        correctAnswerIndex.forEach((item)=>{
          correctAnswer.push(this.optionList[item]);
          this.checkbox.push(this.optionList[item] + ': ' + this.dataList[this.curIndex].options[item].name)
        });
        this.correctAnswer = correctAnswer.join(',');

      }

    },
  }
}
</script>

<style scoped lang="scss">
.data-verify{
  padding: 20px;
  font-size: 14px;
  .data-verify-main{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    position: relative;
    .left-top{
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .answer-cont{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .answer{
      margin-top: 40px;
      margin-bottom: 10px;

      .blue{
        color: #2d8cf0;
      }
      .green{
        color: #19be6b;
      }
      .red{
        color: #ed4014;
      }
    }
    .cont{
      //font-size: 14px;
    }
  }
  .right{
    width: 400px;
    .tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      .tab-list{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFFF;
        >div{
          height: 100%;
          padding: 0 20px;
          border-right: 1px solid #dcdee2;
        }
        >div:last-child{
          border-right: none;
        }
        >div.active{
          background-color: #2d8cf0;
          color: #FFFFFF;
        }
      }
      .count{
        font-size: 14px;
        >span{
          padding: 0 5px;
          color:#2d8cf0;
        }
      }
    }
    .search{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      .amount{
        //border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        //background-color: #FFFFFF;
        >div{
          height: 100%;
          padding: 0 20px;
          border-right: 1px solid #dcdee2;
        }
        >div:last-child{
          border-right: none;
        }
        >div.active{
          background-color: #2d8cf0;
          color: #FFFFFF;
        }
      }
      .search_input{
        font-size: 14px;
        >span{
          padding: 0 5px;
          color:#2d8cf0;
        }
      }
    }
    .catrgory-search{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;


      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;
        height: 380px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            //border: 1px solid #2d8cf0;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;
    }
  }
  .analysis{
    margin-top: 20px;
  }
}
</style>
<style>
.data-verify .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
</style>
